import * as $ from 'jquery'
import {end} from "@popperjs/core";
// Delay execution until required using event delegation:
$(document).on('submit', '[data-behavior="contact"]', handleSubmit)
$(document).on('focus', '#contact-email', handleEmailChange)
$(document).on('focus', '#contact-message', handleMessageChange)

function handleEmailChange(e) {
    $("#contact-email").removeClass('is-invalid')
    $("#contact-email-error").hide()
}

function handleMessageChange(e) {
    $("#contact-message").removeClass('is-invalid')
    $("#contact-message-error").hide()
}

function handleSubmit(e) {
    e.preventDefault()

    const $form = $(e.currentTarget)
    const url = $form.attr('action')
    const action = $form.attr('method')
    const params = $form.serialize()

    $.ajax({
        url: url,
        data: params,
        type: action,
        beforeSend: function() { handleBeforeSend($form) },
        success: function(data) { handleSuccess($form, data) },
        error: function(data) { handleError($form, data) }
    })
}

function handleBeforeSend($form) {
    // Query a child element of the form and alter its state:
    const $btn = $form.find('[type="submit"]')
    $btn.text('Loading...').attr('disabled', true)
}

function handleError($form, data) {
    const response = data.responseJSON

    if(data.status == 422) {
        if(response.hasOwnProperty("email")) {
            $("#contact-email").addClass('is-invalid')
            $("#contact-email-error").text(response.email.join()).show()
        }

        if(response.hasOwnProperty("message")) {
            $("#contact-message").addClass('is-invalid')
            $("#contact-message-error").text(response.message.join()).show()
        }

        const $btn = $form.find('[type="submit"]')
        $btn.text('Send').attr('disabled', false)
    } else {
        //something went wrong with the server
        $("#contact-error").show()
    }
}
function handleSuccess($form, data) {
    const $btn = $form.find('[type="submit"]')
    $btn.text('Sent!').addClass('btn-success').removeClass('btn-dark').attr('disabled', true)
}
